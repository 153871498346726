import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
export interface IEnergySource {
  id: number;
  status: string;
  nameEs: string;
  nameEn: string;
  unitsEs: string;
  unitsEn: string;
  unitCost: number;
  emissionFactor: number;
  emissionScopeEs: string;
  emissionScopeEn: string;
  energyType: string;
  energyContent: number;
  efficiencyFactor: number;
  minValue: number;
  maxValue: number;
  defaultValue: number;
  mostCommon: boolean;
  demoOrder: number;
}
export interface IEnergySourceResult {
  nameEs: string;
  nameEn: string;
  emission: number;
  emissionScopeEs: string;
  emissionScopeEn: string;
  energyCost: number;
  energyContent: number;
}
export interface IResults {
  totalEmissions: number;
  totalEnergyCost: number;
  energySourceResults: IEnergySourceResult[];
  emissionsIntensity: number;
  emissionsIntensityUnit: string;
  energyIntensity: number;
  energyIntensityUnit: string;
  emissionsToReduce: number;
  optimisedEmissions: number;
  emissionsToReduceTreeEquivalence: number;
  costToReduce: number;
  optimisedCosts: number;
}
export interface IActions {
  name: string;
  actions: string[];
  notes: string;
}

const emptyActions: IActions = {
  name: "",
  actions: [],
  notes: "",
};
export interface ICostPrediction {
  date: string;
  costPrediction: number;
}

const emptyCostPrediction: ICostPrediction = {
  date: "",
  costPrediction: 0,
};
export interface ICostSummary {
  date: string;
  dailyCost: number;
}

const emptyCostSummary: ICostSummary = {
  date: "",
  dailyCost: 0,
};
export interface IEmission {
  date: string;
  emission: number;
  emissionScopeEn: string;
  emissionScopeEs: string;
  energyContent: number;
  energyCost: number;
  energySourceEn: string;
  energySourceEs: string;
  equipmentId: string;
  processId: string;
}
const emptyEmission: IEmission = {
  date: "",
  emission: 0,
  emissionScopeEn: "",
  emissionScopeEs: "",
  energyContent: 0,
  energyCost: 0,
  energySourceEn: "",
  energySourceEs: "",
  equipmentId: "",
  processId: "",
};
export interface IEmissionIntensity {
  date: string;
  emissionIntensity: number;
}
const emptyEmissionIntensity: IEmissionIntensity = {
  date: "",
  emissionIntensity: 0,
};
export interface IEmissionPrediction {
  date: string;
  emissionPrediction: number;
}
const emptyEmissionPrediction: IEmissionPrediction = {
  date: "",
  emissionPrediction: 0,
};
export interface IEmissionSummary {
  date: string;
  dailyEmission: number;
}
const emptyEmissionSummary: IEmissionSummary = {
  date: "",
  dailyEmission: 0,
};
export interface IEnergyConsumption {
  date: string;
  energySourceEn: string;
  energySourceEs: string;
  equipmentId: string;
  processId: string;
  unitsEn: string;
  unitsEs: string;
  consumption: number;
}
const emptyEnergyConsumption: IEnergyConsumption = {
  date: "",
  energySourceEn: "",
  energySourceEs: "",
  equipmentId: "",
  processId: "",
  unitsEn: "",
  unitsEs: "",
  consumption: 0,
};
export interface IEnergyCostContribution {
  date: string;
  labelEn: string;
  labelEs: string;
  value: number;
}
const emptyEnergyCostContribution: IEnergyCostContribution = {
  date: "",
  labelEn: "",
  labelEs: "",
  value: 0,
};
export interface IEnergyEmissionContribution {
  date: string;
  labelEn: string;
  labelEs: string;
  value: number;
}
const emptyEnergyEmissionContribution: IEnergyEmissionContribution = {
  date: "",
  labelEn: "",
  labelEs: "",
  value: 0,
};
export interface IEnergyIntensity {
  date: string;
  energyIntensity: number;
}
const emptyEnergyIntensity: IEnergyIntensity = {
  date: "",
  energyIntensity: 0,
};
export interface IEquipmentCostContribution {
  date: string;
  labelEn: string;
  labelEs: string;
  value: number;
}
const emptyEquipmentCostContribution: IEquipmentCostContribution = {
  date: "",
  labelEn: "",
  labelEs: "",
  value: 0,
};
export interface IEquipmentEmissionContribution {
  date: string;
  labelEn: string;
  labelEs: string;
  value: number;
}
const emptyEquipmentEmissionContribution: IEquipmentEmissionContribution = {
  date: "",
  labelEn: "",
  labelEs: "",
  value: 0,
};
export interface IOptimization {
  costCurrent: number;
  costOptimized: number;
  costReduced: number;
  emissionOptimized: number;
  emissionReduced: number;
  emissionWithOutOptimization: number;
  emissionsTreeEquivalence: number;
}
const emptyOptimization: IOptimization = {
  costCurrent: 0,
  costOptimized: 0,
  costReduced: 0,
  emissionOptimized: 0,
  emissionReduced: 0,
  emissionWithOutOptimization: 0,
  emissionsTreeEquivalence: 0,
};
export interface IProcessCostContribution {
  date: string;
  labelEn: string;
  labelEs: string;
  value: number;
}
const emptyProcessCostContribution: IProcessCostContribution = {
  date: "",
  labelEn: "",
  labelEs: "",
  value: 0,
};
export interface IProcessEmissionContribution {
  date: string;
  labelEn: string;
  labelEs: string;
  value: number;
}
const emptyProcessEmissionContribution: IProcessEmissionContribution = {
  date: "",
  labelEn: "",
  labelEs: "",
  value: 0,
};
export interface IProduction {
  date: string;
  unit: string;
  value: number;
}
const emptyProduction: IProduction = {
  date: "",
  unit: "",
  value: 0,
};

export interface IProPlanResults {
  actions: IActions[];
  costPrediction: ICostPrediction[];
  costSummary: ICostSummary[];
  emission: IEmission[];
  emissionIntensity: IEmissionIntensity[];
  emissionPrediction: IEmissionPrediction[];
  emissionSummary: IEmissionSummary[];
  energyConsumption: IEnergyConsumption[];
  energyCostContribution: IEnergyCostContribution[];
  energyEmissionContribution: IEnergyEmissionContribution[];
  energyIntensity: IEnergyIntensity[];
  equipmentCostContribution: IEquipmentCostContribution[];
  equipmentEmissionContribution: IEquipmentEmissionContribution[];
  optimization: IOptimization;
  processCostContribution: IProcessCostContribution[];
  processEmissionContribution: IProcessEmissionContribution[];
  production: IProduction[];
}

const emptyProPlanResults: IProPlanResults = {
  actions: [emptyActions],
  costPrediction: [emptyCostPrediction],
  costSummary: [emptyCostSummary],
  emission: [emptyEmission],
  emissionIntensity: [emptyEmissionIntensity],
  emissionPrediction: [emptyEmissionPrediction],
  emissionSummary: [emptyEmissionSummary],
  energyConsumption: [emptyEnergyConsumption],
  energyCostContribution: [emptyEnergyCostContribution],
  energyEmissionContribution: [emptyEnergyEmissionContribution],
  energyIntensity: [emptyEnergyIntensity],
  equipmentCostContribution: [emptyEquipmentCostContribution],
  equipmentEmissionContribution: [emptyEquipmentEmissionContribution],
  optimization: emptyOptimization,
  processCostContribution: [emptyProcessCostContribution],
  processEmissionContribution: [emptyProcessEmissionContribution],
  production: [emptyProduction],
};
interface State {
  sources: IEnergySource[];
  filteringResultPages: number;
  results: IResults;
  selectedPlan: "free" | "pro";
  proPlanResults: IProPlanResults;
  areProResultsFetched: boolean;
}

const energyEmptyResult: IEnergySourceResult = {
  nameEs: "",
  nameEn: "",
  emission: 0,
  emissionScopeEs: "",
  emissionScopeEn: "",
  energyCost: 0,
  energyContent: 0,
};
const emptyResults: IResults = {
  totalEmissions: 0,
  totalEnergyCost: 0,
  energySourceResults: [energyEmptyResult],
  emissionsIntensity: 0,
  emissionsIntensityUnit: "",
  energyIntensity: 0,
  energyIntensityUnit: "",
  emissionsToReduce: 0,
  optimisedEmissions: 0,
  emissionsToReduceTreeEquivalence: 0,
  costToReduce: 0,
  optimisedCosts: 0,
};
const initialState: State = {
  sources: [],
  filteringResultPages: 0,
  results: emptyResults,
  selectedPlan: "free",
  proPlanResults: emptyProPlanResults,
  areProResultsFetched: false,
};

const planSlice = createSlice({
  name: "planData",
  initialState,
  reducers: {
    saveSources: (state, action: PayloadAction<IEnergySource[]>) => {
      state.sources = action.payload;
    },
    saveFilteringResultPages: (state, action: PayloadAction<number>) => {
      state.filteringResultPages = action.payload;
    },
    clearSources: (state) => {
      state.sources = initialState.sources;
    },
    saveResults: (state, action: PayloadAction<IResults>) => {
      state.results = action.payload;
    },
    saveProPlanResults: (state, action: PayloadAction<IProPlanResults>) => {
      state.proPlanResults = action.payload;
    },
    saveSelectedPlan: (state, action: PayloadAction<"free" | "pro">) => {
      state.selectedPlan = action.payload;
    },
    clearResults: (state) => {
      state.results = emptyResults;
    },
    clearProPlanResults: (state) => {
      state.proPlanResults = emptyProPlanResults;
    },
    setFetchedMockedDataStatus: (state, action: PayloadAction<boolean>) => {
      state.areProResultsFetched = action.payload;
    },
  },
});
export const {
  saveSources,
  saveResults,
  clearResults,
  clearSources,
  saveSelectedPlan,
  clearProPlanResults,
  saveProPlanResults,
  setFetchedMockedDataStatus,
  saveFilteringResultPages
} = planSlice.actions;

export default planSlice.reducer;

export const selectEnergySources = (state: RootState): IEnergySource[] =>
  state.sourcesManagement.sources;
export const selectResults = (state: RootState): IResults =>
  state.sourcesManagement.results;
export const selectFilteringResultPages = (state: RootState): number =>
  state.sourcesManagement.filteringResultPages;
export const selectProPlanResults = (state: RootState): IProPlanResults =>
  state.sourcesManagement.proPlanResults;
export const selectSelectedPlan = (state: RootState): "free" | "pro" =>
  state.sourcesManagement.selectedPlan;
export const selectFetchedProResultsStatus = (state: RootState): boolean =>
  state.sourcesManagement.areProResultsFetched;
