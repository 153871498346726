import { AppDispatch } from "../index";
import { v4 as uuidv4 } from "uuid";
import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import { displaySnackbarAPISuccess, getSnackbarError } from "./utils";
import moment from "moment";
import { store } from "../../store";
import { logOutSession, refresh } from "./authActions";
import {
  saveRoles,
  saveUsage,
  saveUserDetailById,
  saveUsageByUserId,
  saveUsers,
  saveUsageById,
  saveTotalUsersPages,
  saveUsageTotalUsersPages,
  saveUsageResumeTotalPages,
} from "../slices/user";
import { addAPIcall, completeAPIcall } from "../slices/api";

const userURL = getBaseUrlForAPI("user");
const apiURL = getBaseUrlForAPI("api");
const appUsageURL = getBaseUrlForAPI("appUsage");

export type IUsageTypes = string | null;
export type IPaymentTypes = string | null;

export interface IManageUser {
  companyName: string;
  email: string;
  emailVerificationCode?: string;
  passwordChangeCode?: string;
  emailVerified: boolean;
  name: string;
  role: string;
  surveyCompleted: boolean;
  username: string;
  password?: string;
  planDetails?: {
    planUsageType: IUsageTypes;
    planRemainingUsages: number;
    planEndDate: string;
    planPaymentType: IPaymentTypes;
  };
}

export interface IFilterValue {
  field: string;
  filterValue: string;
}

export interface IUserFIlter {
  id?: string;
  status?: string;
  name?: string;
  companyName?: string;
  email?: string;
  role?: string;
  pageSize?: number;
  pageNumber?: number;
  sortType?: string;
  sortBy?: string;
}

export interface IUsageFilter {
  time?: string;
  id?: string;
  user_id?: string;
  user_email?: string;
  dtype?: string;
  pageSize?: number;
  pageNumber?: number;
  sortType?: string;
  sortBy?: string;
}

export const getFilteredUsers =
  (params: IUserFIlter) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `getFilteredUsers-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${userURL}/search`, { params })
        .then(({ data }) => {
          dispatch(saveUsers(data.content));
          dispatch(saveTotalUsersPages(data.totalPages));
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const getRoles =
  () =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `getRoles-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${apiURL}/core/role`)
        .then(({ data }) => {
          dispatch(saveRoles(data));
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const deleteUsers =
  (userId: number, params: IUserFIlter) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `deleteUsers-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .delete(`${userURL}/${userId}`)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.userDeleted"));
          dispatch(getFilteredUsers(params));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const editUser =
  (userId: number, user: IManageUser, params: IUserFIlter) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `editUser-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .put(`${userURL}/${userId}`, user)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.userEdited"));
          dispatch(getFilteredUsers(params));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const createUser =
  (user: IManageUser, params: IUserFIlter) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `createUser-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .post(`${userURL}`, user)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.userCreated"));
          dispatch(getFilteredUsers(params));
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const fetchUsageResume =
  (params: IUsageFilter) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `fetchUsageResume-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${appUsageURL}/search`,{params})
        .then(({ data }) => {
          dispatch(saveUsage(data.content));
          dispatch(saveUsageResumeTotalPages(data.totalPages));
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };
export const fetchFilteredUsageResume =
  (filter: IFilterValue) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `fetchFilteredUsageResume-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${appUsageURL}/search?${filter.field}=${filter.filterValue}`)
        .then(({ data }) => {
          dispatch(saveUsage(data.content));
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const fetchUsageByUserId =
  (userId: number, params?: IUsageFilter) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `fetchUsageByUserId-${uuidv4()}`;
      dispatch(addAPIcall(id));

      axiosInstance
        .get(`${userURL}/${userId}/app-usages/search`, { params })
        .then(({ data }) => {
          dispatch(saveUsageByUserId(data.content));
          dispatch(saveUsageTotalUsersPages(data.totalPages));
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const fetchUsageById =
  (userId: number) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `fetchUsageById-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${appUsageURL}/${userId}`)
        .then(({ data }) => {
          dispatch(saveUsageById(data));
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };

export const fetchUserDetailsById =
  (userId: number) =>
  (dispatch: AppDispatch): void => {
    const loginTime = store.getState().authManagement.loginTime;
    const refreshToken = store.getState().authManagement.user?.refresh_token;
    const idToken = store.getState().authManagement.user?.id_token;
    const refreshTokenTime =
      store.getState().authManagement.user?.refresh_expires_in || 1800;
    const accessTokenTime =
      store.getState().authManagement.user?.expires_in || 300;
    const currentTime = moment().format();
    const difference = moment(currentTime).diff(loginTime) / 1000;

    const localRequest = () => {
      const id = `fetchUserDetailsById-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${userURL}/${userId}`)
        .then(({ data }) => {
          dispatch(saveUserDetailById(data));
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };
    if (difference < accessTokenTime - 10) {
      localRequest();
    } else if (
      difference >= accessTokenTime - 10 &&
      difference < refreshTokenTime - 10
    ) {
      if (refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      }
    } else if (difference >= refreshTokenTime - 10) {
      if (idToken) {
        dispatch(logOutSession({ id_token: idToken }));
      }
    }
  };
