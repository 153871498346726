import React, { useEffect, useState } from "react";
import { useAppDispatch } from "app/hooks";
import PungoInput from "pungo-ui/PungoInput";
import PungoModal from "pungo-ui/PungoModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  fetchUsageById,
  fetchUsageResume,
  fetchUserDetailsById,
} from "store/actions/userActions";
import {
  clearUserDataById,
  selectAllRoles,
  selectGeneralUsage,
  selectUsageById,
  selectUsageResumeTotalPages,
  selectUserDetailById,
} from "store/slices/user";
import AdminTable, { ITableColumn } from "../common/AdminTable";

import styles from "./index.module.scss";

const AdminUsageTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const usageData = useSelector(selectGeneralUsage);
  const detailedData = useSelector(selectUsageById);
  const detailedUserData = useSelector(selectUserDetailById);
  const rolesList = useSelector(selectAllRoles);
  const totalResumePages = useSelector(selectUsageResumeTotalPages);

  const [showUsageModal, setShowUsageModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUsageId, setSelectedUsageId] = useState<number | undefined>();
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>();
  const [pageSize, setPageSize] = useState(10);

  const initialTimeFilter = "";
  const initialIdFilter = "";
  const initialUserIdFilter = "";
  const initialUserNameFilter = "";
  const initialCallTypeFilter = "all";

  const [timeFilter, setTimeFilter] = useState(initialTimeFilter);
  const [idFilter, setIdFilter] = useState(initialIdFilter);
  const [userIdFilter, setUserIdFilter] = useState(initialUserIdFilter);
  const [userNameFilter, setUserNameFilter] = useState(initialUserNameFilter);
  const [callTypeFilter, setcallTypeFilter] = useState(initialCallTypeFilter);

  const [currentTablePage, setCurrentTablePage] = useState(0);
  const [sortBy, setSortBy] = useState("id");
  const [sortType, setSortType] = useState("asc");

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");

  const [filteredUsageTimeoutId, setFilteredUsageTimeoutId] =
  useState(0);

  const filterCallTypeOptions = [
    { label: t("admin.filtering.all"), value: "all" },
    { label: t("admin.filtering.proPlan"), value: "PRO_PLAN" },
    { label: t("admin.filtering.simplePlan"), value: "SIMPLE_PLAN" },
  ];

  const columnsForUsageTable: ITableColumn[] = [
    {
      id: "time",
      field: "time",
      headerName: "Time",
      sortable: false,
      minWidth: 250,
      filter: {
        name: t("admin.filtering.date"),
        type: "text",
        value: timeFilter,
        setter: setTimeFilter,
      },
    },
    {
      id: "id",
      field: "id",
      headerName: "ID",
      sortable: false,
      minWidth: 150,
      filter: {
        name: "ID",
        type: "text",
        value: idFilter,
        setter: setIdFilter,
      },
    },
    {
      id: "userId",
      field: "userId",
      headerName: "User Id",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.userId"),
        type: "text",
        value: userIdFilter,
        setter: setUserIdFilter,
      },
    },
    {
      id: "userName",
      field: "userName",
      headerName: "User's email",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.mail"),
        type: "text",
        value: userNameFilter,
        setter: setUserNameFilter,
      },
    },
    {
      id: "callType",
      field: "callType",
      headerName: "Call type",
      sortable: false,
      minWidth: 200,
      filter: {
        name: t("admin.filtering.type"),
        type: "list",
        value: callTypeFilter,
        setter: setcallTypeFilter,
        options: filterCallTypeOptions,
      },
    },
  ];

  const paramsForUsageResumeTable = {
    ...(timeFilter && { time: timeFilter }),
    ...(idFilter && { id: idFilter }),
    ...(userIdFilter && { user_id: userIdFilter }),
    ...(userNameFilter && { user_email: userNameFilter }),
    ...(callTypeFilter !== "all" && { dtype: callTypeFilter }),
    ...{ pageSize },
    ...{ pageNumber: currentTablePage },
    ...{ sortType },
    ...{ sortBy },
  };

  useEffect(() => {
    if (currentTablePage >= totalResumePages) {
      setCurrentTablePage(0);
    }

    if (filteredUsageTimeoutId) clearTimeout(filteredUsageTimeoutId);
    setFilteredUsageTimeoutId(setTimeout(handleFilterUsage, 1000));
  }, [
    idFilter,
    timeFilter,
    userIdFilter,
    userNameFilter,
    callTypeFilter,
    pageSize,
    currentTablePage,
    sortBy,
    sortType,
    totalResumePages,
  ]);

  function handleFilterUsage(data: Number): void {
    dispatch(fetchUsageResume(paramsForUsageResumeTable));
  }

  useEffect(() => {
    if (selectedUsageId) {
      dispatch(fetchUsageById(selectedUsageId));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsageId]);

  useEffect(() => {
    setSelectedUserId(detailedData.callDetails.userId);
  }, [detailedData]);

  useEffect(() => {
    setName(detailedUserData?.name || "");
    setCompany(detailedUserData?.companyName || "");
    setEmail(detailedUserData?.email || "");
    setRole(detailedUserData?.role || "");
  }, [detailedUserData]);

  useEffect(() => {
    if (selectedUserId) {
      dispatch(fetchUserDetailsById(selectedUserId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserId]);

  const clearForm = () => {
    setSelectedUserId(undefined);
    setName("");
    setCompany("");
    setEmail("");
    setRole("");
    setPassword("");
  };

  const handleOnClose = () => {
    setShowUsageModal(false);
    setSelectedUsageId(undefined);
  };

  const handleOnCloseUserModal = () => {
    dispatch(clearUserDataById());
    setShowUserModal(false);
    clearForm();
  };

  const getUserDetailsModal = () => (
    <PungoModal
      open={showUserModal}
      title={`${t("admin.userDetailsLabel")}`}
      handleClose={handleOnCloseUserModal}
      primaryAction={{
        label: "Ok",
        onClick: handleOnCloseUserModal,
      }}
    >
      <div className={styles.modal}>
        <PungoInput
          name={`${t("user.nameLabel")}`}
          value={name}
          onChange={setName}
          disabled
        />
        <PungoInput
          name={`${t("user.companyLabel")}`}
          value={company}
          disabled
          onChange={setCompany}
        />
        <PungoInput
          name={`${t("user.emailLabel")}`}
          value={email}
          onChange={setEmail}
          disabled
        />
        <PungoInput
          name={`${t("user.roleLabel")}`}
          value={role}
          onChange={setRole}
          disabled
          type="select"
          options={rolesList.map(({ name }) => {
            return { label: name, value: name };
          })}
        />
        <PungoInput
          name={`${t("user.passwordLabel")}`}
          value={password}
          onChange={setPassword}
          disabled
          type="password"
        />
      </div>
    </PungoModal>
  );

  const resetFilters = () => {
    setTimeFilter(initialTimeFilter);
    setIdFilter(initialIdFilter);
    setUserIdFilter(initialUserIdFilter);
    setUserNameFilter(initialUserNameFilter);
    setcallTypeFilter(initialCallTypeFilter);

  };

  const showResetFilters =
    timeFilter!==initialTimeFilter||
    idFilter!==initialIdFilter||
    userIdFilter!==initialUserIdFilter||
    userNameFilter!==initialUserNameFilter||
    callTypeFilter!==initialCallTypeFilter;
    

  return (
    <div>
      <AdminTable
        columns={columnsForUsageTable}
        data={usageData || []}
        actions={["stats", "user"]}
        handleOnResetFilters={resetFilters}
        shouldShowResetFilters={showResetFilters}
        handleOnSelection={setSelectedUsageId}
        handleOnUsage={setShowUsageModal}
        handleOnUser={setShowUserModal}
        sorting={{
          sortedBy: sortBy,
          onSortedBy: setSortBy,
          sortedType: sortType,
          onSortedType: setSortType,
        }}
        pagination={{
          totalPages: totalResumePages,
          currentPage: currentTablePage,
          onCurrentPage: setCurrentTablePage,
          pageSize: pageSize,
          onPageSize: setPageSize,
        }}
      />
      <PungoModal
        open={showUsageModal}
        classNames={styles.detailsModal}
        title={`${t("admin.detailsLabel")}`}
        handleClose={handleOnClose}
        primaryAction={{
          label: "Ok",
          onClick: handleOnClose,
        }}
      >
        <code className={styles.detailsModalChild}>
          <pre>{JSON.stringify(detailedData, null, 4)}</pre>
        </code>
      </PungoModal>
      {getUserDetailsModal()}
    </div>
  );
};

export default AdminUsageTable;
